<template>
  <validation-observer
    ref="agentForm"
  >
    <b-toast
      :visible="isFailed"
      title="Warning"
      variant="warning"
    >
      {{ $t('messages.invalid_form') }}
    </b-toast>
    <form
      autocomplete="off"
      @submit.prevent="onSubmit"
    >
      <b-row
        v-if="agentId"
        class="pt-2"
      >
        <b-col>
          <small class="mb-1">
            <feather-icon
              icon="CalendarIcon"
            />
            {{ $t('user.registered_date') }}
          </small>
          <p class="text-muted">
            {{ agentDetail.createdAt | datetime }}
          </p>
        </b-col>
        <b-col>
          <small class="mb-1">
            <feather-icon
              icon="CalendarIcon"
            />
            อัพเดทล่าสุด
          </small>
          <p class="text-muted">
            {{ agentDetail.updatedAt | datetime }}
          </p>
        </b-col>
        <b-col>
          <small class="mb-1">
            <feather-icon
              icon="ShieldIcon"
            />
            Role
          </small>
          <p class="text-muted">
            {{ agentDetail.agentType }}
          </p>
        </b-col>
        <b-col>
          <small class="mb-1">
            <feather-icon
              icon="DollarSignIcon"
            />
            Credit
          </small>
          <p class="text-muted">
            {{ agentDetail.credit | currency }}
          </p>
        </b-col>
      </b-row>
      <validation-provider
        v-if="!agentId"
        v-slot="{ errors }"
        name="ยูสเซอร์เนม"
        :rules="agentId ? null : 'required'"
      >
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`${$t('user.username')} *`"
          label-for="username"
        >
          <b-form-input
            id="username"
            v-model="agentData.username"
            placeholder="@username"
            :state="errors[0] ? false : null"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="Agent Prefix"
        rules="required"
      >
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`AgentPrefix *`"
          label-for="prefix"
        >
          <b-form-input
            id="prefix"
            v-model="agentData.prefix"
            placeholder="@prefix"
            :state="errors[0] ? false : null"
            :readonly="isAgent"
          />
        </b-form-group>
      </validation-provider>

      <validation-provider
        v-if="!agentId"
        v-slot="{ errors }"
        :name="$t('user.password')"
        :rules="agentId ? null : 'required'"
      >
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`${$t('user.password')} *`"
          label-for="agent_password"
        >
          <b-input-group>
            <b-form-input
              id="agent_password"
              v-model="agentData.password"
              :type="isShowPassword ? 'text' : 'password'"
              :state="errors[0] ? false : null"
              autocomplete="off"
            />
            <template #append>
              <b-button @click="isShowPassword = !isShowPassword">
                <feather-icon
                  :icon="isShowPassword?'EyeIcon':'EyeOffIcon'"
                />
              </b-button>
            </template>
          </b-input-group>
        </b-form-group>
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="name"
        rules="required"
      >
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`ชื่อ *`"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="agentData.name"
            :state="errors[0] ? false : null"
            :readonly="isAgent"
          />
        </b-form-group>
      </validation-provider>
     
      <validation-provider
        v-if="!agentId"
        v-slot="{ errors }"
        name="phoneNumber"
        :rules="agentId ? null : 'required'"
      >
        <b-form-group
          :label="`${$t('user.phone')} *`"
          label-for="phoneNumber"
          label-cols-sm="12"
          label-cols-lg="3"
        >
          <b-form-input
            id="phoneNumber"
            v-model="agentData.phoneNumber"
            v-mask="'##########'"
            :state="errors[0] ? false : null"
            :aria-invalid="false"
            aria-describedby="input-live-feedback"
          />
          <b-form-invalid-feedback id="input-live-feedback">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      
      <validation-provider
        v-slot="{ errors }"
        name="registrationUrl"
        rules="required"
      >
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`${$t('agent.register_url')} *`"
          label-for="registrationUrl"
        >
          <b-input-group>
            <b-form-input
              id="registrationUrl"
              v-model="agentData.registrationUrl"
              :state="errors[0] ? false : null"
              placeholder="ใส่แค่ Domain เท่านั้น ( ตัวอย่างเช่น - example.com )"
              :readonly="isAgent"
            />
          </b-input-group>
        </b-form-group>
      </validation-provider>
      <hr class="my-2">
      <h4 class="mb-4">
        Line signin configuration
      </h4>
      <b-form-group
        abel-cols-sm="12"
        label-cols-lg="3"
        :label="$t('agent.has_line_signin')"
        label-for="isEnableLineAuth"
      >
        <b-input-group>
          <b-form-checkbox
            v-model="agentData.isEnableLineAuth"
            name="isEnableLineAuth"
            switch
          >
            Enable Line signin
          </b-form-checkbox>
        </b-input-group>
      </b-form-group>
      <b-form-group
        abel-cols-sm="12"
        label-cols-lg="3"
        :label="$t('agent.line_client_id')"
        label-for="lineChannelId"
      >
        <b-input-group>
          <b-input
            id="lineChannelId"
            v-model="agentData.lineChannelId"
            type="text"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group
        abel-cols-sm="12"
        label-cols-lg="3"
        :label="$t('agent.line_client_secret')"
        label-for="lineChannelSecret"
      >
        <b-input-group>
          <b-input
            id="lineChannelSecret"
            v-model="agentData.lineChannelSecret"
            type="text"
          />
        </b-input-group>
        <b-form-text>
          ค่าที่ใช้สามารถหาได้จาก
          <b-button
            size="sm"
            variant="link"
            href="https://developers.line.biz/console"
            target="_blank"
          >
            Line developer console
          </b-button>
        </b-form-text>
      </b-form-group>
      <hr class="my-2">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="mb-4">
          {{ $t('contact.title') }}
        </h4>
        <b-button
          v-b-modal.contact-example
          variant="link"
        >
          {{
            $t('messages.example')
          }}
        </b-button>
      </div>
      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        label="LINE"
        label-for="lineId"
      >
        <validation-provider
          v-slot="{ errors }"
          name="lineId"
          rules=""
        >
          <b-input-group prepend="ID">
            <b-input
              id="lineId"
              v-model="agentData.lineId"
              :state="errors[0] ? false : null"
              placeholder="@example"
            />
          </b-input-group>
        </validation-provider>
      </b-form-group>

      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        :label="`${$t('contact.qr_code')}`"
        label-for="qr_code"
      >
        <validation-provider
          v-slot="{ errors }"
          name="line_qr_code"
          rules=""
        >
          <b-form-file
            id="line_qr_code"
            v-model="agentData.lineQrCode"
            accept="image/*"
            readonly
            :state="errors[0] ? false : null"
          />
        </validation-provider>
        <b-form-text>
          <a
            v-if="agentDetail.lineQrCode"
            target="_blank"
            :href="agentDetail.lineQrCode"
          >
            {{ agentDetail.lineQrCode || "" }}
          </a>
          <b-link
            v-if="agentData.lineQrCode && (typeof agentData.lineQrCode !== 'string')"
            class="text-danger"
            @click="agentData.lineQrCode = null"
          >
            {{ $t('buttons.remove') }}
          </b-link>
        </b-form-text>
      </b-form-group>
      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        :label="`${$t('contact.message')}`"
        label-for="contact"
      >
        <validation-provider
          v-slot="{ errors }"
          name="contact"
        >
          <b-form-textarea
            id="contact"
            v-model="agentData.contact"
            :state="errors[0] ? false : null"
          />
        </validation-provider>
      </b-form-group>
      <b-modal
        id="contact-example"
        :title="`${$t('messages.example')} | ${$t('contact.title')}`"
        size="lg"
        hide-footer
      >
        <b-img
          :src="require('@/assets/images/contact-example.png')"
          height="100"
        />
      </b-modal>
      <hr class="my-2">
      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        :label="$t('routes.ANNOUNCEMENTS')"
        label-for="announce"
      >
        <vue-editor v-model="agentData.announce" />
      </b-form-group>
      <hr class="my-2">
      <h4 class="mb-4">
        ตั้งค่าการถอน
      </h4>
      
      <b-form-group
        label-cols-sm="6"
        label-cols-lg="3"
        label="ยอดถอนขั้นต่ำ *"
        label-for="minWithdrawCredit"
      >
        <validation-provider
          v-slot="{ errors }"
          name="minWithdrawCredit"
          rules="required"
        >
          <b-form-input
            id="minWithdrawCredit"
            v-model="agentData.minWithdrawCredit"
            :state="errors[0] ? false : null"
          />
        </validation-provider>
      </b-form-group>
      <b-form-group
        label-cols-sm="6"
        label-cols-lg="3"
        label="จำนวนถอนสูงสุดต่อวัน / ครั้ง *"
        label-for="maxWithdrawCreditTimePerDay"
      >
        <validation-provider
          v-slot="{ errors }"
          name="maxWithdrawCreditTimePerDay"
          rules="required"
        >
          <b-form-input
            id="maxWithdrawCreditTimePerDay"
            v-model="agentData.maxWithdrawCreditTimePerDay"
            :state="errors[0] ? false : null"
          />
        </validation-provider>
      </b-form-group>
      <hr class="my-2">
      <h4 class="mb-4">
        ตั้งค่าระบบเงินคืน
      </h4>
      <b-form-group
        label-cols-sm="6"
        label-cols-lg="3"
        label="เปอร์เซ็นจ่าย Cash back *"
        label-for="cashbackCommissionRate"
      >
        <validation-provider
          v-slot="{ errors }"
          name="cashbackCommissionRate"
          rules="required"
        >
          <b-input-group append="%">
            <b-input
              id="cashbackCommissionRate"
              v-model="agentData.cashbackCommissionRate"
              :state="errors[0] ? false : null"
            />
          </b-input-group>
        </validation-provider>
      </b-form-group>
      <b-form-group 
        label-cols-sm="6"
        label-cols-lg="3"
        label="จำนวนแคชแบคที่แจกสูงสุดต่อวัน *"
        label-for="cashbackQuotaPerDay"
      >
        <validation-provider
          v-slot="{ errors }"
          name="cashbackQuotaPerDay"
          rules="required"
        >
          <b-input
            id="cashbackQuotaPerDay"
            v-model="agentData.cashbackQuotaPerDay"
            :state="errors[0] ? false : null"
          />
        </validation-provider>
      </b-form-group>
      <b-form-group
        label-cols-sm="6"
        label-cols-lg="3"
        label="ถอนขั้นต่ำ (บาท) *"
        label-for="minWithdrawCashback"
      >
        <validation-provider
          v-slot="{ errors }"
          name="minWithdrawCashback"
          rules="required"
        >
          <b-input
            id="minWithdrawCashback"
            v-model="agentData.minWithdrawCashback"
            :state="errors[0] ? false : null"
          />
        </validation-provider>
      </b-form-group>
      <b-form-group 
        label-cols-sm="6"
        label-cols-lg="3"
        label="ถอนสูงสุดต่อวัน (จำนวนครั้ง) *"
        label-for="maxWithdrawCashbackTimePerDay"
      >
        <validation-provider
          v-slot="{ errors }"
          name="maxWithdrawCashbackTimePerDay"
          rules="required"
        >
          <b-input
            id="maxWithdrawCashbackTimePerDay"
            v-model="agentData.maxWithdrawCashbackTimePerDay"
            :state="errors[0] ? false : null"
          />
        </validation-provider>
      </b-form-group>
      <hr class="my-2">
      <h4 class="mb-4">
        ตั้งค่าระบบแนะนำ (AF)
      </h4>
      <b-form-group 
        label-cols-sm="6"
        label-cols-lg="3"
        label="ฝากขั้นต่ำ (บาท) *"
        label-for="minDepositForEnableAffiliate"
      >
        <validation-provider
          v-slot="{ errors }"
          name="minDepositForEnableAffiliate"
          rules="required"
        >
          <b-input
            id="minDepositForEnableAffiliate"
            v-model="agentData.minDepositForEnableAffiliate"
            :state="errors[0] ? false : null"
          />
        </validation-provider>
      </b-form-group>
      <b-form-group 
        label-cols-sm="6"
        label-cols-lg="3"
        label="ถอนขั้นต่ำ (บาท) *"
        label-for="minWithdrawAffiliate"
      >
        <validation-provider
          v-slot="{ errors }"
          name="minWithdrawAffiliate"
          rules="required"
        >
          <b-input
            id="minWithdrawAffiliate"
            v-model="agentData.minWithdrawAffiliate"
            :state="errors[0] ? false : null"
          />
        </validation-provider>
      </b-form-group>
      <b-form-group 
        label-cols-sm="6"
        label-cols-lg="3"
        label="ถอนสูงสุดต่อวัน (จำนวนครั้ง) *"
        label-for="maxWithdrawAffiliateTimePerDay"
      >
        <validation-provider
          v-slot="{ errors }"
          name="maxWithdrawAffiliateTimePerDay"
          rules="required"
        >
          <b-input
            id="maxWithdrawAffiliateTimePerDay"
            v-model="agentData.maxWithdrawAffiliateTimePerDay"
            :state="errors[0] ? false : null"
          />
        </validation-provider>
      </b-form-group>

      <b-table-simple style="min-height: 0!important">
        <b-thead>
          <b-tr>
            <b-th>{{ $t('affiliate.level', {val: ''}) }}</b-th>
            <b-th>{{ $t('affiliate.percentage') + ' (%)' }}*</b-th>
            <b-th />
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-if="agentData.affiliateLevelSettings.length === 0">
            <b-td
              colspan="3"
              class="text-muted"
            >
              ไม่มีข้อมูล
            </b-td>
          </b-tr>
          <b-tr
            v-for="(level, index) in agentData.affiliateLevelSettings"
            :key="level.level"
          >
            <b-td>
              <b-form-group :label-for="`level-${level.level}`">
                <b-form-input
                  :id="`level-${level.level}`"
                  v-model="level.level"
                  readonly
                />
              </b-form-group>
            </b-td>
            <b-td>
              <b-form-group :label-for="`commissionRate-${level.level}`">
                <b-form-input
                  :id="`commissionRate-${level.level}`"
                  v-model="level.commissionRate"
                />
              </b-form-group>
            </b-td>
            <b-td>
              <b-button
                variant="danger"
                :disabled="index < (agentData.affiliateLevelSettings.length - 1)"
                @click="onRemove(index)"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td colspan="3">
              <b-button
                variant="success"
                @click="onAdd"
              >
                <feather-icon
                  icon="PlusIcon"
                />
                เพิ่มเลเวล
              </b-button>
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>

      <hr class="my-2">
      <h4 class="mb-4">
        ตั้งค่าเกม
      </h4>
      <b-table-simple style="min-height: 0!important">
        <b-thead>
          <b-tr>
            <b-th />
            <b-th>รายชื่อเกม</b-th>
            <b-th>คอมมิชชั่น (ค่าคอมฯ ไม่สามารถให้มากกว่าเปอร์เซ็นตัวเองได้)</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-if="gameData.length === 0">
            <b-td
              colspan="3"
              class="text-muted"
            >
              ไม่มีเกม
            </b-td>
          </b-tr>
          <b-tr
            v-for="(game) in gameData"
            :key="game.id"
          >
            <b-td>
              <b-form-group :label-for="`isSelect-${game.id}`">
                <b-form-checkbox
                  :id="`isSelect-${game.id}`"
                  v-model="game.isSelect"
                  switch
                />
              </b-form-group>
            </b-td>
            <b-td>
              <b-form-group :label-for="`name-${game.id}`">
                <b-form-input
                  :id="`name-${game.id}`"
                  v-model="game.name"
                  readonly
                />
              </b-form-group>
            </b-td>
            <b-td>
              <b-form-group :label-for="`gameRate-${game.id}`">
                <b-form-input
                  :id="`gameRate-${game.id}`"
                  v-model="game.commissionRate"
                  :placeholder="game.masterCommissionRate"
                  :readonly="!game.isSelect"
                />
              </b-form-group>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div class="text-right">
        <b-button
          class="mr-2"
          :to="{ name: 'VIEW_AGENTS'}"
          variant="light"
        >
          {{ $t('buttons.back') }}
        </b-button>
        <b-overlay
          :show="isLoading"
          rounded
          spinner-small
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="primary"
            block
          >
            {{ $t('buttons.save') }}
          </b-button>
        </b-overlay>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { mapActions, mapGetters, mapState } from 'vuex'
import { required } from '@validations'

export default {
  components: { VueEditor },
  props: {
    agentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      isFailed: false,

      isShowPassword: false,
      agentData: {
        prefix: '',
        name: '',
        registrationUrl: '',
        minWithdrawCredit: null,
        maxWithdrawCreditTimePerDay: null,
        minWithdrawAffiliate: null,
        maxWithdrawAffiliateTimePerDay: null,
        minDepositForEnableAffiliate: null,
        cashbackCommissionRate: null,
        cashbackQuotaPerDay: null,
        minWithdrawCashback: null,
        maxWithdrawCashbackTimePerDay: null,
        lineChannelId: '',
        lineChannelSecret: '',
        lineId: '',
        lineQrCode: null,
        contact: '',
        announce: '',
        isEnableLineAuth: false,
        affiliateLevelSettings: [],
        agentGameConfigs: [],
        username: '',
        password: '',
        phoneNumber: '',
        credit: null,
      },
      levelData: {
        level: 1,
        commissionRate: '',
      },
      gameData: [],
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.agent.isCreatingAgent || state.agent.isUpdatingAgent
    }),
    ...mapGetters(['isAgent','agentDetail', 'miniGames']),
  },
  watch: {
    miniGames(val) {
      if (val.length > 0) {
        this.setGameData()
      }
    },
    agentDetail(val) {
      if (val) {
        this.setData()
      }
    },
  },
  async created() {
    await this.fetchGames();
    this.setData()
  },
  methods: {
    ...mapActions(['fetchGames', 'createAgent', 'updateAgent']),
    setData() {
      if (this.agentId && this.agentDetail) {
        const data = JSON.parse(JSON.stringify(this.agentDetail))
        this.agentData = { ...this.agentData, ...data }
        this.agentData.lineQrCode = null
        if(data.affiliateLevelSettings && data.affiliateLevelSettings.length > 0){
          this.levelData.level = data.affiliateLevelSettings.length + 1
        }
        if(data.agentGameConfigs && data.agentGameConfigs.length > 0){
          this.setGameData(data.agentGameConfigs)
        }
      } else {
        this.agentData = {
          prefix: '',
          name: '',
          registrationUrl: '',
          minWithdrawCredit: 0,
          maxWithdrawCreditTimePerDay: 0,
          minWithdrawAffiliate: 0,
          maxWithdrawAffiliateTimePerDay: 0,
          minDepositForEnableAffiliate: 0,
          cashbackCommissionRate: 0,
          cashbackQuotaPerDay: 0,
          minWithdrawCashback: 0,
          maxWithdrawCashbackTimePerDay: 0,
          lineChannelId: '',
          lineChannelSecret: '',
          lineId: '',
          lineQrCode: null,
          contact: '',
          announce: '',
          isEnableLineAuth: false,
          affiliateLevelSettings: [],
          agentGameConfigs: [],
          username: '',
          password: '',
          phoneNumber: '',
          credit: 0,
        }
      }
    },
    setGameData(gameConfigs) {
      if(this.miniGames && this.miniGames.length > 0){
        this.gameData = []
        this.miniGames.forEach((game) => {
            if(gameConfigs && gameConfigs.length > 0){
              const found = gameConfigs.find((config) => config.gameConfigId === game.id);
              this.gameData.push({
                ...game,
                gameConfigId: game.id,
                isSelect: !!found,
                commissionRate: found && found.commissionRate? found.commissionRate: game.commissionRate,
                masterCommissionRate: game.commissionRate
              })
            } else {
              this.gameData.push({
                ...game,
                gameConfigId: game.id,
                isSelect: false,
              })
            }
        });
      }
    },
    onRemove(index) {
      this.agentData.affiliateLevelSettings.splice(index, 1)
      this.levelData = {
        level: this.agentData.affiliateLevelSettings.length + 1,
        commissionRate: '',
      }
    },
    onAdd() {
      this.agentData.affiliateLevelSettings.push({
        level: this.agentData.affiliateLevelSettings.length + 1,
        commissionRate: '',
      })
      
    },
    onSubmit() {
      this.$refs.agentForm.validate().then( async (success) => {
        if (success) {
          this.isFailed = false;
          if(this.gameData.length > 0){
            const agentGameConfigs = this.gameData.filter((game) => game.isSelect === true)
            this.agentData.agentGameConfigs = agentGameConfigs.map((game) => { 
              return { gameConfigId: game.gameConfigId, commissionRate: game.commissionRate}
            })
          }
          this.agentData.minWithdrawCredit = +this.agentData.minWithdrawCredit
          this.agentData.maxWithdrawCreditTimePerDay = +this.agentData.maxWithdrawCreditTimePerDay
          this.agentData.minWithdrawAffiliate = +this.agentData.minWithdrawAffiliate
          this.agentData.maxWithdrawAffiliateTimePerDay = +this.agentData.maxWithdrawAffiliateTimePerDay
          this.agentData.cashbackCommissionRate = +this.agentData.cashbackCommissionRate
          this.agentData.cashbackQuotaPerDay = +this.agentData.cashbackQuotaPerDay
          this.agentData.minWithdrawCashback = +this.agentData.minWithdrawCashback
          this.agentData.maxWithdrawCashbackTimePerDay = +this.agentData.maxWithdrawCashbackTimePerDay
          this.agentData.credit = +this.agentData.credit
          if (this.agentId) {
            this.updateAgent({ id: this.agentId, data: this.agentData })
          } else {
            this.createAgent( this.agentData)
          }
        } else {
          this.isFailed = true;
        }
      })
    },
  },
}
</script>
